<template>
  <filter-select
    v-bind="{ ...$attrs, ...$props }"
    @input="$emit('input', $event)"
    @on-selection-changed="$emit('on-selection-changed', $event)"
  />
</template>

<script>
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'LocationFilterSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
      meta: {
        description: 'Elements who can be chosen',
        example: '[4, 8, 15, 16, 23, 42]',
      },
    },
    value: {
      type: [Object, String, Number, Boolean],
      required: false,
      default: () => null,
    },
    fieldvalue: {
      type: String,
      required: true,
      meta: {
        description: 'Field that contains the value that will be chosen',
        example: 'id',
      },
    },
    returnObject: {
      type: Boolean,
      default: () => false,
    },
    isMultiple: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'If the field is gonna be able to select multiple options or not',
        example: 'true',
      },
    },
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
  },
  watch: {
    options(values) {
      if (!values.length || !this.currentActiveLocation || this.isMultiple) return;
      const location = values.find(
        (x) =>
          x[this.fieldvalue] ==
          this.currentActiveLocation.locationConfigurationId,
      );
      if (location) {
        this.$emit(
          'input',
          this.returnObject ? location : location[this.fieldvalue],
        );
        this.$emit('on-selection-changed', location);
      }
    },
  },
};
</script>
