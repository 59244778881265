<template>
  <b-form-group class="c-filterMultiSelect">
    <template :v-slot="label">
      <div class="c-s-filterLabel">
        <span>{{ this.label != null ? this.label + ':' : null }}</span>
        <span v-if="required" class="text-danger">*</span>
      </div>
    </template>

    <div class="c-s-filterMultiSelect" :class="icon">
      <div v-if="icon" class="icon" />
      <div
        v-if="selectAllEnabled && areAllOptionsSelected && options.length > 0"
        class="c-selectAllFacade"
        @click="deselectAllOptions"
      >
        <span>{{ $t('all') }}</span>
        <i class="fas fa-times" v-if="!disabled"/>
      </div>
      <br />
      <v-select
        v-if="selectAllEnabled ? !areAllOptionsSelected : true"
        :id="id"
        v-model="model"
        :class="label == null ? 'noLabel' : ''"
        :clearable="clearable"
        :disabled="disabled"
        :label="fieldtext"
        :multiple="isMultiple"
        :name="name"
        :options="options"
        :placeholder="placeholder"
        :selectable="selectable"
        :style="label == null ? 'font-weight: bold' : ''"
        @input="onSelectionsChanged"
      />
    </div>
    <button
      v-if="
        isMultiple && allowSelectAll && selectAllEnabled && options.length > 0 && !disabled
      "
      class="c-selectAllButton"
      @click="selectAllOptions"
    >
      {{ $t('selectall') }}
    </button>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </b-form-group>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    Error,
  },
  props: {
    id: {
      type: String,
      default: () => '',
      meta: {
        description: 'Id of the tag',
        example: 'ddlOffice',
      },
    },
    name: {
      type: String,
      default: () => '',
      meta: {
        description: 'Name of the tag',
        example: 'ddlOffice',
      },
    },
    label: {
      type: String || null,
      default: () => null, // Taken from the locale dictionary
      required: false,
      meta: {
        description: 'Label text',
        example: 'Office',
      },
    },
    placeholder: {
      type: String,
      default: 'select', // Taken from the locale dictionary
      meta: {
        description: 'Texto de referencia para el CustomDropDown',
        example: 'Select',
      },
    },
    required: {
      type: Boolean,
      default: function () {
        return false;
      },
      meta: {
        description: 'Required flag',
      },
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      meta: {
        description: 'Disabled flag',
      },
    },
    options: {
      type: Array,
      default: () => [],
      meta: {
        description: 'Elements who can be chosen',
        example: '[4, 8, 15, 16, 23, 42]',
      },
    },
    value: {
      type: Array,
      default: () => [],
    },
    fieldvalue: {
      type: String,
      required: true,
      meta: {
        description: 'Field that contains the value that will be chosen',
        example: 'id',
      },
    },
    fieldtext: {
      type: String,
      required: true,
      meta: {
        description: 'Field that contains the text to display',
        example: 'name',
      },
    },
    clearable: {
      type: Boolean,
      default: () => false,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true',
      },
    },
    isMultiple: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'If the field is gonna be able to select multiple options or not',
        example: 'true',
      },
    },
    selectAllEnabled: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'Whether or not display a button to select all options at once',
      },
    },
    icon: {
      type: String,
      default: null,
      meta: {
        description:
          'Go down, to the css section and look for a list of available icons. Use any class identifier found there.',
      },
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    allowSelectAll: {
      type: Boolean,
      default: () => true,
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.model = newVal;
      }
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  computed: {
    hasDisabledValue() {
      return (
        Array.isArray(this.model) &&
        this.model.length > 0 &&
        this.model.some((x) => x.disabled == true)
      );
    },
    areAllOptionsSelected() {
      return (
        this.options.length > 0 && this.value.length === this.options.length
      );
    },
  },
  methods: {
    onSelectionsChanged(values) {
      this.$emit('change', values);
    },
    selectAllOptions() {
      if (this.options.length > 0) {
        this.$emit('change', this.options);
      }
    },
    deselectAllOptions() {
      if(!this.disabled)
        this.$emit('change', []);
    },
  },
};
</script>
<style scoped lang="scss">
.c-filterMultiSelect {
  .c-s-filterLabel {
    color: $color-font-secondary;
    padding-bottom: calc(0.375rem + 1px) !important;
  }
  .c-s-filterMultiSelect {
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid $color-label-default;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 39px;

    ::v-deep .vs__dropdown-option.vs__dropdown-option--selected {
      display: none;
    }

    ::v-deep .vs__clear {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::v-deep .vs__dropdown-toggle {
      border: none;
      min-height: 20px;
    }

    ::v-deep .vs__dropdown-option {
      text-overflow: ellipsis;
      overflow: hidden;
      border-top: solid $color-label-default 1px;
      white-space: normal;
    }

    ::v-deep .v-select {
      flex: 100% 1 1;
    }
    ::v-deep .vs__selected {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 2;
      flex-direction: row-reverse;
      button {
        margin-right: 5px;
      }
    }
    ::v-deep .vs__search {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0 !important;
      top: 0;
    }

    &.clock {
      ::v-deep .vs__selected-options {
        padding-left: 40px;
      }

      .icon {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        /*ICONS IDENTIFIER*/
        &:after {
          content: '\f017';
          font-family: 'Font Awesome 5 Pro', sans-serif;
          font-size: 14px;
          color: $color-primary;
          margin-right: 13px;
        }
      }
    }

    .c-selectAllFacade {
      background-color: $color-white;
      border: 1px solid rgba(60, 60, 60, 0.26);
      border-radius: 4px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 5px;

      i {
        color: rgba(60, 60, 60, 0.5);
        margin-left: 5px;
      }
    }
  }

  .c-selectAllButton {
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    margin-top: 10px;
    text-decoration: underline;
  }
}
.noLabel {
  ::v-deep .vs__search {
    font-weight: bold !important;
  }
}
</style>
