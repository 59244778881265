<template>
  <div class="pb-1 filtermanager">
    <validation-observer ref="formValidator" novalidate>
      <div class="row">
        <div
          v-for="filter in mainFilters"
          :key="filter.name"
          :class="`${filter.class || 'col'} col-sm mb-2`"
        >
          <validation-provider v-slot="{ errors }" :rules="filter.rules">
            <component
              :is="filter.component"
              v-model="value[filter.name]"
              :allow-select-all="filter.allowSelectAll"
              :class-container="filter.classContainer"
              :clearable="filter.clearable"
              :disabled="disabled(filter)"
              :error="filter.error && errors[0] ? filter.error : errors[0]"
              :error-msg.sync="
                filter.error && errors[0] ? filter.error : errors[0]
              "
              :fieldtext="filter.fieldtext"
              :fieldvalue="filter.fieldvalue"
              :format="filter.format"
              :i="filter.icon"
              :icon="filter.icon"
              :is-inline="filter.isInline"
              :is-multiple="filter.isMultiple"
              :label="filter.label"
              :left-icon="filter.leftIcon"
              :mask-type="filter.maskType"
              :masked="filter.masked != undefined ? filter.masked : false"
              :max-datetime="filter.maxDateTime"
              :min-datetime="filter.minDateTime"
              :name="
                filter.componentName != undefined ? filter.componentName : ''
              "
              :options="filter.options"
              :placeholder="filter.placeholder"
              :required="filter.required"
              :select-all-enabled="filter.selectAllEnabled"
              :selectable="filter.selectable"
              :textarea="filter.textarea"
              :title="filter.label"
              :type="filter.type"
              :use-utc="filter.useUtc"
              :variant="filter.variant"
              @change="changeFilter(filter, $event)"
              @input="changeFilter(filter, $event)"
            />
          </validation-provider>
        </div>
        <slot />
        <div
          v-if="searchText"
          :class="`${searchContainerClass} actions-container col ml-0 mr-0`"
        >
          <div
            class="d-flex justify-content-md-end justify-content-sm-start no-actionbtn-width"
          >
            <base-filled-button
              class="mr-3 pl-3"
              v-if="showExport"
              :icon-class="exportIcon"
              :on-click="OnExport"
              :text="exportText"
            />

            <base-filled-button
              v-if="showAdvancedOptions"
              class="mr-3 pl-3 btn-advanced-search"
              icon-class="fal fa-sliders-v"
              :on-click="OnAdvancedButtonClick"
              text=""
            />

            <base-filled-button
              class="mx-0"
              :disabled="disabledSearch"
              :icon-class="searchIcon"
              :on-click="OnSearch"
              :text="searchText"
            />
          </div>
        </div>
      </div>

      <div v-if="advanced" class="row">
        <div v-if="$slots.header_advanced" class="col-12">
          <slot name="header_advanced" />
        </div>
        <div
          v-for="filter in advancedFilters"
          :key="filter.name"
          :class="`${filter.class || 'col'} col-sm mb-2`"
        >
          <validation-provider v-slot="{ errors }" :rules="filter.validations">
            <component
              :is="filter.component"
              v-model="value[filter.name]"
              :allow-select-all="filter.allowSelectAll"
              :class-container="filter.classContainer"
              :clearable="filter.clearable"
              :disabled="disabled(filter)"
              :error="filter.error && errors[0] ? filter.error : errors[0]"
              :error-msg.sync="
                filter.error && errors[0] ? filter.error : errors[0]
              "
              :fieldtext="filter.fieldtext"
              :fieldvalue="filter.fieldvalue"
              :format="filter.format"
              :i="filter.icon"
              :icon="filter.icon"
              :is-inline="filter.isInline"
              :is-multiple="filter.isMultiple"
              :label="filter.label"
              :left-icon="filter.leftIcon"
              :mask-type="filter.maskType"
              :masked="filter.masked != undefined ? filter.masked : false"
              :max-datetime="filter.maxDateTime"
              :min-datetime="filter.minDateTime"
              :name="
                filter.componentName != undefined ? filter.componentName : ''
              "
              :options="filter.options"
              :placeholder="filter.placeholder"
              :required="filter.required"
              :selectable="filter.selectable"
              :textarea="filter.textarea"
              :title="filter.label"
              :type="filter.type"
              :use-utc="filter.useUtc"
              :variant="filter.variant"
              @change="changeFilter(filter, $event)"
              @input="changeFilter(filter, $event)"
            />
          </validation-provider>
        </div>
        <slot name="advanced" />
      </div>
    </validation-observer>
  </div>
</template>
<script>
import FilterMultiSelect from '@/components/FilterMultiSelect';
import FilterSelect from '@/components/FilterSelect';
import LocationFilterSelect from '@/components/LocationFilterSelect';
import LocationFilterMultiSelect from '@/components/LocationFilterMultiSelect';
import BaseInput from '@/components/BaseInput.vue';
import BaseFilledButton from '../components/basics/buttons/BaseFilledButton.vue';
import BaseDatetimePicker from '@/components/BaseDatetimePicker.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    displayAdvanced: {
      type: Boolean,
      default: () => false,
    },
    openAdvanced: {
      type: Boolean,
      default: () => false,
    },
    disabledSearch: {
      type: Boolean,
      default: () => false,
    },
    searchText: {
      type: String,
      default: () => 'Buscar',
    },
    searchIcon: {
      type: String,
      default: () => 'fa fa-search',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    minDateTime: {
      type: Date,
      default: () => new Date('1980'),
    },
    searchContainerClass: {
      type: String,
      default: () => '',
    },
    showExport: {
      type: Boolean,
      default: () => false,
    },
    exportText: {
      type: String,
      default: () => 'Exportar',
    },
    exportIcon: {
      type: String,
      default: () => 'far fa-download',
    },
  },
  components: {
    FilterMultiSelect,
    FilterSelect,
    LocationFilterMultiSelect,
    LocationFilterSelect,
    BaseFilledButton,
    BaseDatetimePicker,
    BaseInput,
    BaseSwitch,
    BaseCheckbox,
  },
  data() {
    return {
      advanced: false,
      showAdvancedOptions: this.displayAdvanced,
    };
  },
  computed: {
    filtersVisible() {
      return this.filters.filter(
        (filter) => filter.visible || filter.visible == null,
      );
    },
    advancedFilters() {
      return this.filtersVisible.filter((filter) => filter.isAdvanced);
    },
    mainFilters() {
      return this.filtersVisible.filter(
        (filter) => !filter.isAdvanced || filter.isAdvanced == null,
      );
    },
    today() {
      return this.$moment().format();
    },
  },
  watch: {
    openAdvanced: {
      handler(newValue) {
        this.advanced = newValue;
      },
      immediate: true,
    },
  },
  mounted() {
    //Execute methods immediate
    this.filtersVisible.forEach((filter) => {
      if (filter.immediateOnChanged) {
        filter.onChanged(this.value[filter.name]);
      }
    });
    this.filters.forEach((filter) => {
      if (filter.clearModelsOnUpdate) {
        // is array
        this.$watch(`value.${filter.name}`, () => {
          filter.clearModelsOnUpdate.forEach((other) => {
            if (typeof other == 'string') {
              this.value[other] = null;
            } else if (typeof other == 'object') {
              this.value[other.name] = other.default;
            }
          });
        });
      }
    });
  },
  created() {
    //initialize every filter value
    this.filters.forEach((filter) => {
      this.$set(
        this.value,
        filter.name,
        this.value[filter.name] || filter.initialValue,
      );
    });
  },
  methods: {
    OnAdvancedButtonClick() {
      this.advanced = !this.advanced;
    },
    async search(props = {}) {
      const isValid = await this.$refs.formValidator.validate();
      this.$emit('search', isValid ? { ...this.value, ...props } : null);
    },
    assignValue(key, value) {
      this.value[key] = value;
    },
    changeFilter(filter, e) {
      if (filter.onChanged) {
        filter.onChanged(e);
      }
    },
    disabled(filter) {
      if (filter.disabled) {
        if (typeof filter.disabled == 'boolean') {
          return filter.disabled;
        }

        let isDisabled = filter.disabled.some((other) => {
          let filterConfig = this.filtersVisible.find((x) => x.name == other);
          return this.value[other] == null && filterConfig;
        });

        return isDisabled;
      }
      return false;
    },
    async OnSearch() {
      this.$emit('beforeSearch');
      this.$nextTick(async () => {
        const isValid = await this.$refs.formValidator.validate();
        if (!isValid) {
          return;
        }
        this.$emit('search');
      });
    },
    async OnExport() {
      this.$emit('beforeExport');
      this.$nextTick(async () => {
        const isValid = await this.$refs.formValidator.validate();
        if (!isValid) {
          return;
        }
        this.$emit('export');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 23px !important;
}

.btn-search {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.filtermanager .actions-container {
  margin-top: 1.75rem;
  margin-bottom: auto;
}

::v-deep .c-filledButton.btn-advanced-search {
  min-width: 38px;
  width: 38px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 !important;
}

::v-deep .c-filledButton.btn-advanced-search i {
  margin: 0;
  padding: 0;
}
</style>
