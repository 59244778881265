
<template>
  <filter-multi-select
    v-bind="{ ...$attrs, ...$props }"
    @change="$emit('change', $event)"
  />
</template>

<script>
import FilterMultiSelect from '@/components/FilterMultiSelect';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'LocationFilterMultiSelect',
  components:  {
      FilterMultiSelect
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      meta: {
        description: 'Elements who can be chosen',
        example: '[4, 8, 15, 16, 23, 42]',
      },
    },
    value: {
      type: Array,
      default: () => [],
    },
    fieldvalue: {
      type: String,
      required: true,
      meta: {
        description: 'Field that contains the value that will be chosen',
        example: 'id',
      },
    },
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
  },
  watch: {
    options(values) {
      if (!values.length || !this.currentActiveLocation) return;
      const location = values.find(
        (x) => x[this.fieldvalue] == this.currentActiveLocation.locationConfigurationId,
      );
      if (location) this.$emit('change', location);
    },
  },
};
</script>
